import "swiper/css";
import "swiper/css/pagination";

import { type CSSProperties, useEffect, useId, useRef } from "react";
import { Swiper as SwiperType } from "swiper";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { Accordion } from "@/components/Accordion.tsx";
import { SliderArrowLeft } from "@/icons/SliderArrowLeft.tsx";
import { SliderArrowRight } from "@/icons/SliderArrowRight.tsx";
import type { AccordionTabsProps } from "@/types";
import { cn } from "@/utils/cn.ts";

export const AccordionTabs = ({
  tabs,
  activeTab,
  setActiveTab,
  idPostfix = "",
}: AccordionTabsProps) => {
  const id = useId();
  const swiperRef = useRef<{ swiper: SwiperType } | null>(null);

  const goToPreviousSlide = () => {
    const swiper = swiperRef?.current?.swiper;

    if (!swiper) {
      return;
    }

    swiper.slidePrev();
  };

  const goToNextSlide = () => {
    const swiper = swiperRef?.current?.swiper;

    if (!swiper) {
      return;
    }

    swiper.slideNext();
  };

  useEffect(() => {
    const swiper = swiperRef?.current?.swiper;

    if (!swiper) {
      return;
    }

    if (activeTab === swiper.realIndex) {
      return;
    }

    swiper.slideTo(activeTab);
  }, [activeTab]);

  if (!tabs.length) {
    return null;
  }

  return (
    <section id="faqs">
      {tabs.map(({ id }, index) => (
        <div key={index} id={`${id}-${idPostfix}`} />
      ))}
      <div className={cn("py-[38px] bg-light-green hidden", "md:block")}>
        <div className="container max-w-[1188px]">
          <div className="flex gap-[17px]" role="tablist">
            {tabs.map(({ image, alt, title }, index) => (
              <button
                key={index}
                className={cn(
                  "relative w-[calc(20%-14px)] p-4 bg-white rounded-md overflow-hidden flex flex-col justify-center items-center gap-[17px] transition scale-100",
                  "hover:scale-110",
                  "before:content-[''] before:absolute before:bottom-0 before:left-0 before:w-full before:bg-light-blue before:transition before:origin-left before:h-[10px] before:duration-300 before:scale-x-0",
                  { "before:scale-x-100": index === activeTab },
                )}
                role="tab"
                aria-selected={index === activeTab}
                onClick={() => setActiveTab(index)}
                aria-controls={`panel-${id}-${index}`}
                id={`tab-${id}-${index}`}
              >
                <img
                  src={image.src}
                  alt={alt}
                  width={105}
                  height={102}
                  loading="lazy"
                />
                <h3 className="text-center text-blue font-mulish text-md font-extrabold leading-[1.2]">
                  {title}
                </h3>
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className={cn("block pt-[42px] bg-light-green", "md:hidden")}>
        <div className="container">
          <h2 className="text-white text-center font-bold text-[26px] leading-none mb-[22px]">
            FAQs
          </h2>

          <div className="relative">
            <button
              className={cn(
                "text-white absolute p-2 z-10 top-[calc(50%-70px)] left-[calc(50%-170px)] opacity-100 transition-opacity duration-300",
                "hover:opacity-60",
                "sm:left-[calc(50%-210px)]",
              )}
              aria-label={"Change slide to previous one"}
              onClick={goToPreviousSlide}
            >
              <SliderArrowLeft className="w-[17px] h-[32px]" />
            </button>

            <Swiper
              className={"accordion-slider !pb-[70px]"}
              style={
                {
                  "--swiper-pagination-bottom": "32px",
                  "--swiper-pagination-bullet-horizontal-gap": "5px",
                  "--swiper-pagination-bullet-width": "13px",
                  "--swiper-pagination-bullet-height": "13px",
                  "--swiper-pagination-bullet-inactive-color": "#FFFFFF",
                  "--swiper-pagination-bullet-inactive-opacity": "1",
                  "--swiper-pagination-color": "#FFFFFF",
                } as CSSProperties
              }
              spaceBetween={50}
              slidesPerView={1}
              ref={swiperRef}
              modules={[Pagination]}
              pagination={{ clickable: true }}
              grabCursor
              loop
              onSlideChange={({ realIndex }) => setActiveTab(realIndex)}
            >
              {tabs.map(({ image, alt, title }, index) => (
                <SwiperSlide key={index}>
                  <div
                    className={cn(
                      "flex flex-col px-[26px] py-[32px] bg-white w-full max-w-[214px] mx-auto justify-center gap-[26px] items-center rounded-[5px] overflow-hidden",
                      "sm:max-w-[300px]",
                    )}
                  >
                    <img
                      className={cn(
                        "w-[101px] h-[105px]",
                        "sm:w-[151px] sm:h-[158px]",
                      )}
                      src={image.src}
                      alt={alt}
                      width={101}
                      height={105}
                    />

                    <h3 className="text-center font-mulish font-extrabold text-blue text-md leading-[1.2]">
                      {title}
                    </h3>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            <button
              className={cn(
                "text-white absolute p-2 z-10 top-[calc(50%-70px)] right-[calc(50%-170px)] opacity-100 transition-opacity duration-300",
                "hover:opacity-60",
                "sm:right-[calc(50%-210px)]",
              )}
              aria-label={"Change slide to next one"}
              onClick={goToNextSlide}
            >
              <SliderArrowRight className="w-[17px] h-[32px]" />
            </button>
          </div>
        </div>
      </div>

      <div className={cn("mt-[38px] mb-[47px]", "md:mt-[53px]")}>
        <div className={"container max-w-[1188px]"}>
          <h2
            className={cn(
              "mb-[53px] text-center text-2xl leading-[1.286] text-dark-blue-black hidden",
              "md:block",
            )}
          >
            Frequently Asked Questions
          </h2>

          <div className={cn("flex flex-col gap-[15px]")}>
            {tabs.map(({ accordions }, index) => (
              <div
                key={index}
                className={cn("w-full flex flex-col gap-[15px]", {
                  hidden: index !== activeTab,
                })}
                id={`panel-${id}-${index}`}
                role="tabpanel"
                aria-labelledby={`tab-${id}-${index}`}
              >
                {accordions.map(({ title, description }, index) => (
                  <Accordion
                    key={index}
                    title={title}
                    openByDefault={index === 0}
                  >
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                  </Accordion>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
