import attentionCard from "@public/images/attention-card.svg";
import chat from "@public/images/chat.svg";
import fincen from "@public/images/fincen.svg";
import judge from "@public/images/judge.svg";
import search from "@public/images/search.svg";

import type { AccordionTabsProps } from "@/types";

const images = {
  "attention-card": attentionCard,
  chat,
  fincen,
  judge,
  search,
};

export const faqsContent: AccordionTabsProps["tabs"] = [
  {
    id: "boir",
    image: images["attention-card"],
    alt: "BOIR FAQs",
    title: "BOIR FAQs",
    accordions: [
      {
        title: "What is BOI Reporting?",
        description:
          "Beneficial Ownership Information (BOI) Reporting involves compiling and submitting the personal identifying information of business owners, addresses and other identifiers to FinCEN.",
      },
      {
        title: "What is the Purpose of a BOI Report?",
        description:
          "The primary purpose of a Beneficial Ownership Information (BOI) Report is to promote transparency between business owners and the U.S. Treasury, helping to prevent unlawful activities.",
      },
      {
        title: "How do I File a BOI Report?",
        description:
          "Individuals can file Beneficial Ownership Information (BOI) Reports using FileDirect, a secure e-filing software designed to simplify the reporting process, via some attorneys and accountants, or by visiting FinCEN direct.",
      },
      {
        title: "Where is the BOI Report Form?",
        description:
          "The Beneficial Ownership Information BOI) Report Form is transmitted electronically after completing submission on FileDirect. It can also be found on the FinCEN website.",
      },
      {
        title: "Where Does the BOI Report Go?",
        description:
          "Once the Beneficial Ownership Information (BOI) Report is completed and securely e-filed using FileDirect, it is forwarded to FinCEN and stored on secure Department of Treasury servers.",
      },
    ],
  },
  {
    id: "fincen",
    image: images.fincen,
    alt: "FinCEN FAQs",
    title: "FinCEN FAQs",
    accordions: [
      {
        title: "What is FinCEN?",
        description:
          "FinCEN, or the Financial Crimes Enforcement Network, is a bureau of the U.S. Department of the Treasury. Its mission is to safeguard the financial system by detecting and preventing money laundering, terrorist financing, and other financial crimes.",
      },
      {
        title: "What is BOIR?",
        description:
          "BOIR stands for Beneficial Ownership Information Report, and comes under the jurisdiction of FinCEN. This mandatory filing requires businesses to disclose the identities of individuals who own or control a significant portion of the business, or whom have significant control over the business, with the aim of helping to enhance transparency and combat financial crimes.",
      },
      {
        title: "Are trusts required to submit a FinCEN BOIR?",
        description:
          "It depends. However, if the trust filed a document with a secretary of state or similar, then this typically will require them to file a BOIR as defined by the Corporate Transparency Act.",
      },
      {
        title: "What are the primary functions of FinCEN?",
        description:
          "FinCEN, a bureau of the US Department of Treasury, has a mission of safeguarding the financial system from illicit activity, deterring money laundering and the financing of terrorism, and promotion of national security through the strategic use of financial authorities and the collection, analysis, and dissemination of financial intelligence.",
      },
      {
        title:
          "What information is required for FinCEN's Beneficial Ownership Information Report (BOIR)?",
        description:
          "Business owners must provide identifying information about themselves and their businesses, as required by the Beneficial Ownership Information Report (BOIR). This information typically includes names, addresses, dates of birth, and official identification numbers, among other relevant details.",
      },
    ],
  },
  {
    id: "beneficial-owner",
    image: images.judge,
    alt: "Beneficial Owner FAQs",
    title: "Beneficial Owner FAQs",
    accordions: [
      {
        title: "What is a beneficial owner of a business?",
        description:
          "A beneficial owner is an individual with substantial control over a business. This typically includes anyone who owns at least 25% of the business or has significant influence over key decision-making processes.",
      },
      {
        title: "What is a Beneficial Owner Information Report?",
        description:
          "A Beneficial Ownership Information (BOI) Report is required under the Corporate Transparency Act, which is enforced by FinCEN. This report mandates businesses to disclose identifying information about their beneficial owners in the interests of national security and to ensure transparency and compliance with regulatory requirements.",
      },
      {
        title: "What business types have a beneficial owner?",
        description:
          "Most business types, including corporations, LLCs, and partnerships have beneficial owners. These are individuals who own or control a significant portion of the business or its decision-making processes.",
      },
      {
        title: "What is a beneficial owner for BOIR submission purposes?",
        description:
          "The corporate transparency act defines a beneficial owner as an individual who directly or indirectly owns or controls at least 25% of a company's equity or has significant influence over its operations.",
      },
      {
        title:
          "What is the difference between a beneficial owner and a shareholder?",
        description:
          "A shareholder is someone who legally owns shares in a company and has certain rights, such as voting or receiving dividends. A beneficial owner, on the other hand, is the person who ultimately benefits from or controls the shares, even if they are held by another individual or entity on their behalf. In some cases, the shareholder and beneficial owner may be the same person, but not always.",
      },
    ],
  },
  {
    id: "reporting-company",
    image: images.chat,
    alt: "Reporting Company FAQs",
    title: "Reporting Company FAQs",
    accordions: [
      {
        title: "Are some companies exempt from the BOI reporting requirement?",
        description:
          'Companies that are generally exempt from BOI reporting include those classified as "large operating companies," which have more than 20 full time employees, over $5 million in gross annual revenue, and an operating presence in the U.S., and whom have filed incorporation or similar paperwork with a secretary of state or equivalent. Publicly traded companies, certain regulated entities (such as banks and insurance companies), and many nonprofit organizations are also exempt. <br /> <br /> For an accurate assessment, take the exemption quiz here to find out if your company needs to submit a BOI Report.',
      },
      {
        title: "Is a sole proprietorship a reporting company?",
        description:
          "A sole proprietorship typically is not considered a reporting company, however if it has filed paperwork with a secretary of state or equivalent, it is considered a reporting company, and in turn, required to file a BOIR.",
      },
      {
        title: "Do the BOI reporting requirements apply to S-Corporations?",
        description:
          "Yes, BOI reporting requirements apply to S-corporations. If an S-corporation has less than 20 full time employees, generates less than $5 million in gross annual revenue, is operating in the U.S., and has filed paperwork with a secretary of state, it must file a BOI report.",
      },
      {
        title:
          "Can a company created or registered in a U.S. territory be considered a reporting company?",
        description:
          'Yes, a company created or registered in a U.S. territory is considered a reporting company under the BOI reporting requirements.  <br /> <br />  U.S. territories such as Puerto Rico, Guam, the U.S. Virgin Islands, and other territories are included in the definition of "United States" for these regulations. Therefore, companies registered in these locations must comply with the BOIR rules if they meet the criteria for a reporting company, even if they were created internationally.',
      },
    ],
  },
  {
    id: "corporate-transparency-act",
    image: images.search,
    alt: "Corporate Transparency Act FAQs",
    title: "Corporate Transparency Act FAQs",
    accordions: [
      {
        title: "What is the Corporate Transparency Act?",
        description:
          "The Corporate Transparency Act (CTA) was enacted in 2021 to increase transparency and combat illegal activities like money laundering and tax evasion. <br /> <br /> The CTA requires companies in the U.S. to report information about their beneficial owners to the Financial Crimes Enforcement Network (FinCEN) via submitting a BOI Report.",
      },
      {
        title:
          "Who is considered a beneficial owner under the Corporate Transparency Act?",
        description:
          "Under the Corporate Transparency ACT (CTA), a beneficial owner is any person who directly or indirectly controls at least 25% of a company. This includes those who are significantly involved in decision-making relating to a company, such as senior officers.",
      },
      {
        title: "Are there any exemptions from the Corporate Transparency Act?",
        description:
          "Yes, there are 23 exemptions from the Corporate Transparency Act (CTA). One large exemption group consists of entities that meet the following criteria:  more than 20 full time employees, over $5 million in gross annual revenue, an operating presence in the U.S., and whom have filed entity related documentation with a secretary of state or similar.  Also, public companies and regulated entities like banks, credit unions, and insurance companies are exempt from the reporting requirements. <br /> <br /> For an accurate assessment, take the exemption quiz here to find out if your company needs to submit a BOI Report.",
      },
      {
        title: "What information is reported about beneficial owners?",
        description:
          "The information reported about beneficial owners typically includes their full name, date of birth, residential address, and a unique identifying number from an official document (such as a driver's license or passport). Other information may be required depending on the specific circumstances of the business or as mandated by regulatory updates.",
      },
    ],
  },
];
