import { useEffect, useState } from "react";

import { AccordionTabs } from "@/components/AccordionTabs/AccordionTabs.tsx";
import { faqsContent } from "@/content/faqs.ts";
import type { AccordionTabsProps } from "@/types";

export const Faqs = ({ tabs }: Pick<AccordionTabsProps, "tabs">) => {
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    const url = new URL(window.location.href);
    const hash = url.hash.replace(/#|-faqs/g, "");

    if (!hash) {
      return;
    }

    const activeTab = tabs.findIndex(({ id }) => id === hash);

    if (activeTab === -1) {
      return;
    }

    setActiveTab(activeTab);
  }, []);

  return (
    <AccordionTabs
      idPostfix="faqs"
      tabs={faqsContent}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
    />
  );
};
